import React from 'react'
import { isSafari } from 'react-device-detect'
// @src imports
import { DefaultError, FloatingDrawer, Icon, Transition } from 'src/chrome'
import { getPaperTypes } from 'src/app/constants'
import VariationToggle from 'src/editor/components/VariationToggle/VariationToggle'
// import { getTotal } from 'src/helpers'
import { getFlags } from 'src/legacy_graphql'
import { CardPanelView } from 'src/orders/components'
import {
  CardFlip,
  CardTypeOptions,
  PaperTypeOptions,
} from 'src/catalog/components'
import { CardOption } from 'src/catalog/components/CardOptions/CardOptions'
// relative imports
import styles from './cardPreview.module.scss'
import {
  getRouteCardType,
  getRoutePaperType,
} from '../../../redux/selectors/catalog'
import {
  useActions,
  useAppcues,
  useCallback,
  useEffect,
  useFlag,
  // useMemo,
  useMutations,
  useQueries,
  useSelector,
  useState,
} from 'src/hooks'
import * as Result from 'src/utils/Result'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
// import { FlexRow } from 'src/styled'
import {
  Button,
  Div,
  Flex,
  HStack,
  Icon as QdsIcon,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import NewEditorOptionModal from 'src/chrome/NewEditorOptionModal/NewEditorOptionModal'
import { useCreateCard, useGetCard, useGetSendableCard } from 'src/react_query'
import {
  CardPaperType,
  CardType,
  CreateCardMutationVariables,
  DetailedSendableCardFragment,
  SendableCardWithTemplateFragment,
} from 'src/graphql/generated/graphql'
import { CustomCardEditRoute } from 'src/card/routes/CustomCardEditRoute'

export const cardTypes: CardOption<CardType>[] = [
  {
    value: CardType.Flatcard,
    label: 'Flat Card',
    icon: 'FLATPANEL',
    iconSize: 30,
    viewBox: '0 0 25 32',
    canBeBulk: true,
  },
  {
    value: CardType.TwoPanel,
    label: '2-Panel',
    icon: 'STANDARDCARD',
    iconSize: 30,
    viewBox: '0 0 18 32',
    canBeBulk: true,
  },
  {
    value: CardType.ThreePanel,
    label: '3-Panel',
    icon: 'TRIFOLD',
    iconSize: 30,
    viewBox: '0 0 27 32',
    canBeBulk: false,
  },
  {
    value: CardType.TwoPanelBig,
    label: 'Big Card',
    icon: 'BIGCARD',
    iconSize: 30,
    viewBox: '0 0 41 32',
    upgrade: '$3.00',
    canBeBulk: false,
  },
  {
    value: CardType.Postcard,
    label: 'Post Card',
    icon: 'POSTCARD',
    iconSize: 30,
    viewBox: '0 0 48 32',
    canBeBulk: false,
  },
]

const getVariations = (
  sendableCard: DetailedSendableCardFragment,
): SendableCardWithTemplateFragment[] => {
  return [sendableCard, ...sendableCard.variations]
}

const getSelectedVariation = (
  sendableCard: DetailedSendableCardFragment,
  variationId?: string,
): SendableCardWithTemplateFragment =>
  (variationId &&
    getVariations(sendableCard).find(
      variation => variation.id === variationId,
    )) ||
  sendableCard

const isDefined = <T extends {} | undefined>(value: T) =>
  typeof value !== 'undefined'

interface Props {
  onClose: () => void
  onSelect: (id: string) => void
  type: 'SendableCard' | 'Card'
  id: string
}

const CardPreview: React.FC<Props> = props => {
  const { onClose, onSelect, type, id: cardId } = props

  const actions = useActions()
  const routeCardType = useSelector(getRouteCardType)
  const routePaperType = useSelector(getRoutePaperType)
  const isMobile = useSelector(state => state.window.isMobile)
  const { order } = useSelector(state => state.orders)

  const mutations = useMutations()

  // Once these are all migrated to React Query Run them in Parallel
  const [flags] = useQueries(getFlags())
  const getSendableCardQuery = useGetSendableCard(
    {
      id: type === 'SendableCard' ? cardId : undefined,
    },
    { suspense: true },
  )
  const getCardQuery = useGetCard(
    {
      id: type !== 'SendableCard' ? cardId : undefined,
    },
    { suspense: true },
  )
  const sendableCard = getSendableCardQuery.data?.sendableCard
  const card = type === 'SendableCard' ? sendableCard : getCardQuery.data?.card

  const {
    bulkOrderFlow: canShowBulkOrderOptions,
    newCardEditor: canShowNewCardEditor,
  } = useFlag(flags)

  const [variationId, setVariationId] = useState<string | undefined>(undefined)
  const [isCardSelected, setIsCardSelected] = useState(
    isDefined(routeCardType) || isDefined(routePaperType),
  )
  const [cardType, setCardType] = useState(routeCardType ?? CardType.TwoPanel)
  const [isCardFlipped, setIsCardFlipped] = useState(false)
  const [paperType, setPaperType] = useState(
    routePaperType ?? CardPaperType.Std,
  )
  const [isCustomizeOpen, setIsCustomizeOpen] = useState(
    isDefined(routeCardType) || isDefined(routePaperType),
  )
  const createCardMutation = useCreateCard()

  const canSelectPostcardType = !(
    order?.lines.length === 1 && order.lines[0].giftVariation
  )

  const filteredCardTypes = cardTypes.filter(
    type => type.value !== 'POSTCARD' || canSelectPostcardType,
  )

  const paperUpgradeCost = cardType === CardType.TwoPanelBig ? 150 : 50

  const [
    shouldShowEditorChoiceModal,
    setShouldShowEditorChoiceModal,
  ] = useState<boolean>(false)

  // const cost = card.cost

  // const isFree =
  //   cost &&
  //   (cost.total as Array<{ amount: number }>).reduce(
  //     (sum, price) => sum + price.amount,
  //     0,
  //   ) === 0

  // const getPrice = useMemo(() => {
  //   // We only need this until point prices go up.
  //   if (!cost) {
  //     return undefined
  //   }

  //   const total = getTotal(cost)
  //   const pointsDiscount = total.indexOf('3 points') > -1 ? '5 points' : ''

  //   return (
  //     <>
  //       <Div outset={{ top: isMobile ? 'x_5' : 'x3' }}>
  //         <span className={styles.price}>
  //           <Div>
  //             <Div display="inline-block">
  //               <Text type="caption">PRICE</Text>
  //             </Div>
  //           </Div>
  //         </span>
  //         {pointsDiscount && (
  //           <Div display="inline-block">
  //             <Text
  //               type="subtitle"
  //               weight="bold"
  //               isStrikeThrough={true}
  //               outset={{ right: 'x1' }}
  //             >
  //               {`${pointsDiscount}`}
  //             </Text>
  //           </Div>
  //         )}
  //         <Div display="inline-block">
  //           <Text type="subtitle">
  //             {`${pointsDiscount && ' '}${getTotal(cost)}${
  //               pointsDiscount && ' -'
  //             }`}
  //           </Text>
  //         </Div>
  //         {pointsDiscount && (
  //           <>
  //             <Text type="body" color="primaryHeading">
  //               discount ends Jan 1
  //             </Text>
  //           </>
  //         )}
  //         <Div display="inline-block">
  //           <Text
  //             type="footnote"
  //             outset={{ left: pointsDiscount ? 'x0' : 'x1' }}
  //           >
  //             + plus postage
  //           </Text>
  //         </Div>
  //       </Div>
  //     </>
  //   )
  // }, [cost, isMobile])

  const formattedPaperUpgrade = (() => {
    const paperCostString = paperUpgradeCost.toString()

    return paperUpgradeCost < 100
      ? `${paperUpgradeCost}¢`
      : `$${paperCostString.slice(0, 1)}.${paperCostString.slice(1)}`
  })()

  const paperTypes = getPaperTypes(cardType).map(type => ({
    ...type,
    upgrade: type.upgrade ? formattedPaperUpgrade : type.upgrade,
  }))

  useAppcues(isCardSelected ? '-LB2LReuyJSgtvsgG-06' : '-LBXiFzIGUsyytnR23-s')

  const handleSelectCard = useCallback(() => {
    setIsCardSelected(!isCardSelected)
    setIsCustomizeOpen(!isCustomizeOpen)
  }, [isCardSelected, isCustomizeOpen])

  useEffect(() => {
    handleSetPaperType(
      // Postcards may only have standard paper type
      cardType === CardType.Postcard ? CardPaperType.Std : paperType,
    )
  }, [cardType, paperType])

  const handleSetCardType = (value: CardType) => setCardType(value)

  const handleSetPaperType = (value: CardPaperType) => setPaperType(value)

  const handleCardFlip = () => setIsCardFlipped(!isCardFlipped)

  const handleVariation = (variation: SendableCardWithTemplateFragment) =>
    setVariationId(variation.id)

  const variations =
    type === 'SendableCard' && sendableCard
      ? getVariations(sendableCard)
      : undefined
  const selectedVariation =
    type === 'SendableCard' && sendableCard
      ? getSelectedVariation(sendableCard, variationId)
      : undefined

  const mergedSendableCard: DetailedSendableCardFragment | undefined =
    type === 'SendableCard' && sendableCard && selectedVariation
      ? {
          ...sendableCard,
          ...selectedVariation,
          description: sendableCard.description,
          title: sendableCard.title,
        }
      : undefined

  const orientation = (card || mergedSendableCard || { isHorizontal: false })
    .isHorizontal
    ? 'horizontal'
    : 'portrait'

  const cardSizing: string = (
    card ||
    mergedSendableCard || { isHorizontal: false }
  ).isHorizontal
    ? styles.landscape
    : styles.portrait

  const createCard = async ({
    card,
    sendableCard,
    type,
    paperType,
    isNewEditorCard,
  }: CreateCardMutationVariables) => {
    try {
      const { createCard } = await createCardMutation.mutateAsync({
        card,
        sendableCard,
        type,
        paperType,
        isNewEditorCard,
      })
      actions.loadedUser(Result.success(createCard.account))
      onSelect(createCard.card.id)
    } catch (error) {
      console.error(error)
    }
  }

  const handleEditCard = async (
    id: string,
    cardType: CardType,
    paperType: CardPaperType,
    isNewEditorCard: boolean = false,
  ) => {
    await createCard({
      sendableCard: id,
      type: cardType,
      paperType,
      isNewEditorCard,
    })
  }

  const handleEditCustomCard = (id: string) =>
    actions.openCard(id, CustomCardEditRoute())

  const handleSendCustomCard = async (
    isNewEditorCard: boolean | undefined = undefined,
  ) => {
    await createCard({ card: cardId, isNewEditorCard })
  }

  const handleFavoriteCard = async (id: string, isFavorite: boolean) => {
    try {
      if (isFavorite) {
        await mutations.unfavoriteCard({ id })
      } else {
        await mutations.favoriteCard({ id })
      }
    } catch (error) {
      console.error('Failed to favorite/unfavorite card.')
    }
  }

  return (
    <div className={styles.cardPreview}>
      {canShowNewCardEditor && shouldShowEditorChoiceModal && (
        <NewEditorOptionModal
          title={'Your choice! Where would you like to create your card?'}
          description={
            'As a member of our exclusive BETA test you get to\n choose where you would like to edit your card.\nSelect from the options below.'
          }
          tryNewEditor={{
            title: 'Try The New Editor',
            onOverrideClick: () => {
              const id = selectedVariation?.id ?? card?.id
              if (id === cardId && type !== 'SendableCard') {
                handleSendCustomCard(true)
              } else if (id && type === 'SendableCard') {
                handleEditCard(id, cardType, paperType, true)
              }
              setShouldShowEditorChoiceModal(false)
            },
          }}
          useCurrentEditor={{
            title: 'Current Editor',
            onClick: () => {
              const id = selectedVariation?.id ?? card?.id
              if (id === cardId && type !== 'SendableCard') {
                handleSendCustomCard()
              } else if (id && type === 'SendableCard') {
                handleEditCard(id, cardType, paperType, false)
              }
              setShouldShowEditorChoiceModal(false)
            },
          }}
          isOpen={shouldShowEditorChoiceModal}
          onClose={() => {
            setShouldShowEditorChoiceModal(false)
          }}
        />
      )}
      <div
        className={
          orientation === 'horizontal'
            ? `${styles.cardModal} ${styles.horizontalCard}`
            : orientation === 'portrait'
            ? `${styles.cardModal} ${styles.portraitCard}`
            : styles.cardModal
        }
      >
        <div
          className={
            isCustomizeOpen
              ? `${styles.previewContainer} ${styles.configurations}`
              : styles.previewContainer
          }
          id={'preview_container'}
        >
          {isMobile && <h4 style={{ textAlign: 'center' }}>Card Preview</h4>}
          <div className={styles.card}>
            {card?.__typename === 'SendableCard' && (
              <CardFlip
                isFlipped={isCardFlipped}
                card={mergedSendableCard ?? card}
                isButtonEnabled={false}
                customCardSize={cardSizing}
                handleFavoriteCard={handleFavoriteCard}
              />
            )}
            {card?.__typename === 'Card' && <CardPanelView card={card} />}
            <div
              style={
                {
                  position: 'absolute',
                  bottom: 10,
                  left: '50%',
                  transform: 'translateX(-50%)',
                } as React.CSSProperties
              }
            >
              {selectedVariation && variations && variations.length > 1 && (
                <VariationToggle
                  style={{
                    bottom: 0,
                    boxShadow: '0px 2px 12px 0 #595959',
                    background: '#FFF',
                  }}
                  className={
                    orientation === 'horizontal'
                      ? `${styles.catalogVariation} ${styles.horizontalVariation}`
                      : `${styles.catalogVariation} ${styles.portraitVariation}`
                  }
                  variations={variations}
                  selectedVariationId={selectedVariation.id}
                  selectVariation={handleVariation}
                />
              )}
            </div>
          </div>
          <div className={styles.cardDetails}>
            <div className={styles.closePreview} onClick={() => onClose()}>
              <Icon icon={'CLOSE'} size={18} />
              <Text type="caption" color="primaryHeading">
                Close
              </Text>
            </div>
            <div className={styles.nonMobile}>
              {!isCardSelected && (
                <div className={styles.details}>
                  <Text type="title">
                    {mergedSendableCard?.title ?? 'Custom Card'}
                  </Text>
                  {mergedSendableCard?.isPremium && (
                    <div className={styles.premiumTag}>
                      <Icon icon={'TAG'} size={18} color={'#F171CC'} />
                      <Div display="inline-block">
                        <Text
                          type="caption"
                          weight="bold"
                          color="primaryHeading"
                        >
                          Premium Card
                        </Text>
                      </Div>
                    </div>
                  )}
                  <Spacer space="x2" />
                  <Text type="caption" color="primaryHeading">
                    {mergedSendableCard?.description ?? ''}
                  </Text>
                </div>
              )}
              {type === 'Card' && card && (
                <>
                  <div className={styles.actions}>
                    <Button
                      id={'edit_and_save_custom_card_btn'}
                      type="primary"
                      size="medium"
                      title="Edit and Save"
                      onClick={() => handleEditCustomCard(card.id)}
                      outlined={true}
                      fullWidth={true}
                    />
                    <Spacer orientation="horizontal" space="x2" />
                    <Button
                      id={'custom_card_to_editor_btn'}
                      type="primary"
                      size="medium"
                      title={
                        createCardMutation.isLoading
                          ? 'Loading...'
                          : 'Send Card'
                      }
                      onClick={() => {
                        if (
                          canShowNewCardEditor &&
                          card &&
                          card.id === cardId &&
                          card.__typename === 'Card' &&
                          !card.isNewEditorCard
                        ) {
                          setShouldShowEditorChoiceModal(true)
                        } else {
                          handleSendCustomCard()
                        }
                      }}
                      fullWidth={true}
                      disabled={createCardMutation.isLoading}
                    />
                  </div>
                  {isSafari && <div style={{ height: '120px' }} />}
                </>
              )}
            </div>
            {mergedSendableCard && (
              <div
                className={styles.actions}
                style={
                  isMobile
                    ? {
                        position: 'fixed',
                        bottom: '0px',
                        left: '0px',
                        right: '0px',
                      }
                    : { marginBottom: '25%' }
                }
              >
                {mergedSendableCard.insideRightImage && (
                  <>
                    <Button
                      outlined={true}
                      title={isCardFlipped ? 'See Front' : 'See Inside'}
                      id={isCardFlipped ? 'see_front_btn' : 'see_inside_btn'}
                      onClick={handleCardFlip}
                    />
                    <Spacer space="x6" orientation="horizontal" />
                  </>
                )}
                <FloatingDrawer
                  isOpen={isCustomizeOpen}
                  triggerTitle={'Customize'}
                  triggerCloseTitle={'Cancel'}
                  onClick={handleSelectCard}
                  className={styles.action}
                >
                  {isCardSelected && (
                    <div className={styles.options}>
                      <Div
                        inset={{ top: 'x4', horizontal: 'x2', bottom: 'x2' }}
                        backgroundColor="white"
                      >
                        <Text type="title" alignment="center">
                          {!['3666591', '3666592'].includes(
                            mergedSendableCard ? mergedSendableCard.id : '',
                          )
                            ? 'Customize Card Options'
                            : 'Enjoy the creative process!'}
                        </Text>
                      </Div>
                      <Spacer space="x2" />
                      <CardTypeOptions
                        options={filteredCardTypes}
                        optionTitle={'Pick a card type:'}
                        selectedOption={cardType}
                        selectOption={handleSetCardType}
                      />
                      {['FLATCARD', 'TWO_PANEL'].includes(cardType) ? (
                        <Flex style={{ marginLeft: '10px' }}>
                          {canShowBulkOrderOptions && (
                            <HStack
                              alignItems="center"
                              inset="x1"
                              justify="flex-start"
                              gap="x_5"
                            >
                              <Div
                                borderStyle="solid"
                                borderColor={{
                                  swatch: 'success',
                                  shade: 'base',
                                }}
                                borderRadius="circle"
                                style={{
                                  lineHeight: 0,
                                  borderWidth: '2px',
                                  padding: '2px',
                                }}
                              >
                                <QdsIcon
                                  primaryColor="success"
                                  size="small"
                                  name="check"
                                />
                              </Div>
                              <Text weight="bold" type="body">
                                Eligible for bulk send
                              </Text>
                            </HStack>
                          )}
                        </Flex>
                      ) : (
                        <Flex style={{ marginLeft: '10px' }}>
                          {canShowBulkOrderOptions && (
                            <HStack
                              alignItems="center"
                              inset="x1"
                              justify="flex-start"
                              gap="x_5"
                            >
                              <Div
                                borderStyle="solid"
                                borderColor={{
                                  swatch: 'danger',
                                  shade: 'base',
                                }}
                                borderRadius="circle"
                                style={{
                                  lineHeight: 0,
                                  borderWidth: '2px',
                                  padding: '4px',
                                }}
                              >
                                <QdsIcon
                                  primaryColor="danger"
                                  size="xSmall"
                                  name="close"
                                />
                              </Div>
                              <Text color="danger" weight="bold" type="body">
                                Not eligible for bulk send
                              </Text>
                            </HStack>
                          )}
                        </Flex>
                      )}
                      <Flex inset={{ bottom: '90px' }}>
                        <PaperTypeOptions
                          options={paperTypes}
                          optionTitle={'Choose Paper Type:'}
                          selectedOption={paperType}
                          selectOption={handleSetPaperType}
                        />
                      </Flex>
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        left="0"
                        position="fixed"
                        backgroundColor="background"
                        bottom={isMobile ? '80px' : '0'}
                        inset="x1"
                        width="100%"
                        zIndex={1001}
                      >
                        <Button
                          size="large"
                          fullWidth={true}
                          fontWeight="bold"
                          title={
                            createCardMutation.isLoading ? 'Loading...' : 'Next'
                          }
                          id={'card_preview_personalize_btn'}
                          onClick={() => {
                            if (canShowNewCardEditor) {
                              setShouldShowEditorChoiceModal(true)
                            } else {
                              const id = selectedVariation?.id ?? card?.id
                              if (id) {
                                handleEditCard(id, cardType, paperType, false)
                              }
                            }
                          }}
                          disabled={createCardMutation.isLoading}
                        />
                      </Flex>
                    </div>
                  )}
                </FloatingDrawer>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.modalWindow} onClick={() => onClose()} />
    </div>
  )
}

export default suspenseBoundary({
  component: CardPreview,
  unresolved: (
    <Transition
      message={'Loading your selection...'}
      messageStyle={{ color: 'black', fontWeight: 500 }}
    />
  ),
  failure: DefaultError,
})
