import React from 'react'
// @src imports
import Pricing from 'src/marketing/plans/containers/Pricing'
import { DefaultError, Transition } from 'src/chrome'
import ContextBar from 'src/editor/components/ContextBar/ContextBar'
import { CardCategoryRoute } from 'src/catalog/routes/CardCategoryRoute'
import { CardCollectionRoute } from 'src/catalog/routes/CardCollectionRoute'
import { CustomCardRoute } from 'src/catalog/routes/CustomCardRoute'
import { CustomCardsRoute } from 'src/catalog/routes/CustomCardsRoute'
import { FavoriteCardsRoute } from 'src/catalog/routes/FavoriteCardsRoute'
import { LegacyPicturePlusCardsRoute } from 'src/catalog/routes/LegacyPicturePlusCardsRoute'
import { PhotoDropCardsRoute } from 'src/catalog/routes/PhotoDropCardsRoute'
import { SendableCardRoute } from 'src/catalog/routes/SendableCardRoute'
import FullWindowModal from 'src/chrome/FullWindowModal/FullWindowModal'
// relative imports
import {
  BlankCardModal,
  CardCategory,
  CardPreview,
  // CardPreview,
  Collection,
} from '../components'
import { Props } from '../types'
import useApi from '../api'
import AllCategories from '../components/AllCategories/AllCategories'

import styles from './catalog.module.scss'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { useActions, usePersistedUserData, useSelector } from 'src/hooks'
import OrderContextBar from 'src/orders/components/OrderContextBar/OrderContextBar'
import { countLineItems } from 'src/orders/helpers'
import { CatalogCardsRoute } from '../routes/CatalogCardsRoute'
import CardPreviewModal from '../components/CardPreview/CardPreviewModal'

const CardCatalog: React.FC<Props> = props => {
  const {
    onSelect,
    context,
    onCancel,
    subroute,
    orderApi,
    actionContext,
  } = props
  const actions = useActions()

  const api = useApi(props)
  const { controlData } = usePersistedUserData()

  // TODO: placeholder for Act
  // const [flags] = useQueries(getFlags())
  // const { actDisplay: shouldShowAct } = useFlag(flags)
  const shouldShowAct = false

  const { orders } = useSelector(state => state)
  const shouldShowBlankCardModal = !!controlData.isBlankCardModalOpen
  const hasContextBar = !!(context && context.headerText)

  const content = (() => {
    if (!subroute) return <AllCategories api={api} />

    switch (subroute.path) {
      case CardCollectionRoute.path:
        return <Collection collectionId={subroute.cardCollectionId} />
      case CardCategoryRoute.path:
        return (
          <CardCategory
            hasContextBar={hasContextBar}
            api={api}
            displayedCards={{ type: 'category', id: subroute.cardCategoryId }}
          />
        )
      case CustomCardsRoute.path:
      case CustomCardRoute.path:
        return (
          <CardCategory
            hasContextBar={hasContextBar}
            api={api}
            displayedCards={{ type: 'custom' }}
          />
        )
      case FavoriteCardsRoute.path:
        return (
          <CardCategory
            hasContextBar={hasContextBar}
            api={api}
            displayedCards={{ type: 'favorites' }}
          />
        )
      case LegacyPicturePlusCardsRoute.path:
        return (
          <CardCategory
            hasContextBar={hasContextBar}
            api={api}
            displayedCards={{ type: 'legacy-picture-plus' }}
          />
        )
      case PhotoDropCardsRoute.path:
        return (
          <CardCategory
            hasContextBar={hasContextBar}
            api={api}
            displayedCards={{ type: 'premium' }}
          />
        )
      case SendableCardRoute.path:
        return <AllCategories api={api} />
      case CatalogCardsRoute.path:
        return (
          <CardCategory
            hasContextBar={hasContextBar}
            api={api}
            displayedCards={{ type: 'catalog-card' }}
          />
        )
    }
  })()

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div className={styles.catalogWrapper} id={'tour_catalogWrapper'}>
        {context && context.headerText && (
          <ContextBar
            title={context.headerText}
            lines={context.lines}
            subtitle={context.headerSubText}
            activeCardId={context.activeCardId}
          />
        )}

        {content}

        {orders.order && onCancel && orderApi && (
          <OrderContextBar
            isMiniCartButtonLeft={true}
            miniCart={{
              orderId: orders.order.id,
              containerId: 'catalog_context_bar',
              button: {
                count: countLineItems(orders.order.lines),
                shouldBypassDrawer: false,
              },
              previewZIndex: 400,
              orderApi: orderApi,
              checkoutAction: {
                title: 'Cancel & Go to Cart',
              },
            }}
            label={{ title: 'Cards', count: orders.order.lines.length }}
            extraActions={[
              {
                id: 'catalog_secondary_action_btn',
                label:
                  actionContext === 'replacing' ? 'Cancel Replace' : 'Cancel',
                onClick: onCancel,
              },
            ]}
          />
        )}

        {api.previewedCard && (
          <>
            {shouldShowAct ? (
              <CardPreviewModal
                type={api.previewedCard.type}
                id={api.previewedCard.id}
                onSelect={onSelect}
                onClose={api.previewedCard.onClose}
              />
            ) : (
              <CardPreview
                type={api.previewedCard.type}
                id={api.previewedCard.id}
                onSelect={onSelect}
                onClose={api.previewedCard.onClose}
              />
            )}
          </>
        )}

        {shouldShowBlankCardModal && (
          <BlankCardModal
            onClick={id => api.handlePreview(id, 'SendableCard')}
            onClose={() => actions.toggleIsBlankCardModalOpen()}
            style={{ width: 750 }}
          />
        )}
      </div>
      {api.showPlans && (
        <FullWindowModal
          close={() => api.setShowPlans(false)}
          closeMessage={'Skip'}
        >
          <Pricing />
        </FullWindowModal>
      )}
    </div>
  )
}

export default suspenseBoundary({
  component: CardCatalog,
  unresolved: <Transition />,
  failure: DefaultError,
})
