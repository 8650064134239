import React from 'react'
// @src imports
import { DefaultError, Transition } from 'src/chrome'
import { CardOption } from 'src/catalog/components/CardOptions/CardOptions'
// relative imports
import {
  getRouteCardType,
  getRoutePaperType,
} from '../../../redux/selectors/catalog'
import { useActions, useEffect, useSelector, useState } from 'src/hooks'
import * as Result from 'src/utils/Result'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
// import { CardPreviewFlow } from 'src/act/components/CardPreview/CardPreview'
import { useCreateCard, useGetCard, useGetSendableCard } from 'src/react_query'
import {
  CardPaperType,
  CardType,
  CreateCardMutationVariables,
} from 'src/graphql/generated/graphql'

export const cardTypes: CardOption<CardType>[] = [
  {
    value: CardType.Flatcard,
    label: 'Flat Card',
    icon: 'FLATPANEL',
    iconSize: 30,
    viewBox: '0 0 25 32',
    canBeBulk: true,
  },
  {
    value: CardType.TwoPanel,
    label: '2-Panel',
    icon: 'STANDARDCARD',
    iconSize: 30,
    viewBox: '0 0 18 32',
    canBeBulk: true,
  },
  // {
  //   value: CardType.THREE_PANEL,
  //   label: '3-Panel',
  //   icon: 'TRIFOLD',
  //   iconSize: 30,
  //   viewBox: '0 0 27 32',
  //   canBeBulk: false,
  // },
  {
    value: CardType.TwoPanelBig,
    label: 'Big Card',
    icon: 'BIGCARD',
    iconSize: 30,
    viewBox: '0 0 41 32',
    upgrade: '$1.50',
    canBeBulk: false,
  },
  {
    value: CardType.Postcard,
    label: 'Post Card',
    icon: 'POSTCARD',
    iconSize: 30,
    viewBox: '0 0 48 32',
    canBeBulk: false,
  },
]

// const getVariations = (
//   sendableCard: DetailedSendableCardFragment,
// ): SendableCardWithTemplateFragment[] => {
//   return [sendableCard, ...sendableCard.variations]
// }

// const getSelectedVariation = (
//   sendableCard: DetailedSendableCardFragment,
//   variationId?: string,
// ): SendableCardVariationFragment =>
//   (variationId &&
//     getVariations(sendableCard).find(
//       variation => variation.id === variationId,
//     )) ||
//   sendableCard

// const isDefined = <T extends {} | undefined>(value: T) =>
//   typeof value !== 'undefined'

interface Props {
  onClose: () => void
  onSelect?: (id: string) => void
  type: 'SendableCard' | 'Card'
  id: string
}

const CardPreviewModal: React.FC<Props> = props => {
  const { onClose, onSelect, type, id: cardId } = props

  const actions = useActions()
  const routeCardType = useSelector(getRouteCardType)
  const routePaperType = useSelector(getRoutePaperType)

  const createCardMutation = useCreateCard()

  // const storedData = useSelector(state => state.context)

  // const isDigital =
  //   storedData.kind === 'ACT_CONTEXT' ? storedData.isDigitalCard : false

  // const handleIsDigital = () => {
  //   actions.setIsDigitalCard(!isDigital)
  //   console.log(isDigital, 'are we digital')
  // }

  const getSendableCardQuery = useGetSendableCard(
    {
      id: type === 'SendableCard' ? cardId : undefined,
    },
    { suspense: true },
  )
  const getCardQuery = useGetCard(
    {
      id: type !== 'SendableCard' ? cardId : undefined,
    },
    { suspense: true },
  )
  const sendableCard = getSendableCardQuery.data?.sendableCard
  const card = type === 'SendableCard' ? sendableCard : getCardQuery.data?.card

  // const [isCardSelected, setIsCardSelected] = useState(
  //   isDefined(routeCardType) || isDefined(routePaperType),
  // )

  const [cardType, setCardType] = useState(routeCardType ?? CardType.TwoPanel)

  const [paperType, setPaperType] = useState(
    routePaperType ?? CardPaperType.Std,
  )
  // const [isCustomizeOpen, setIsCustomizeOpen] = useState(
  //   isDefined(routeCardType) || isDefined(routePaperType),
  // )

  // const handleSelectCard = useCallback(() => {
  //   setIsCardSelected(!isCardSelected)
  //   setIsCustomizeOpen(!isCustomizeOpen)
  // }, [isCardSelected, isCustomizeOpen])

  useEffect(() => {
    handleSetPaperType(
      // Postcards may only have standard paper type
      cardType === CardType.Postcard ? CardPaperType.Std : paperType,
    )
    // TODO: Remove this when properly applying this code
    handleSetCardType(CardType.TwoPanel)
  }, [cardType, paperType])

  const handleSetCardType = (value: CardType) => setCardType(value)

  const handleSetPaperType = (value: CardPaperType) => setPaperType(value)

  const createCard = async ({
    card,
    sendableCard,
    type,
    paperType,
    isNewEditorCard,
  }: CreateCardMutationVariables) => {
    try {
      const { createCard } = await createCardMutation.mutateAsync({
        card,
        sendableCard,
        type,
        paperType,
        isNewEditorCard,
      })
      actions.loadedUser(Result.success(createCard.account))
      if (onSelect) {
        onSelect(createCard.card.id)
        return
      }
      actions.openCard(createCard.card.id)
    } catch (error) {
      console.log(error)
    }
  }

  const handleEditCard = (
    id: string,
    cardType: CardType,
    paperType: CardPaperType,
    isNewEditorCard: boolean = false,
  ) => {
    onClose()
    createCard({
      sendableCard: id,
      type: cardType,
      paperType,
      isNewEditorCard,
    })
  }

  const isTrue = false
  if (isTrue) console.log('asd', handleEditCard)

  // const handleEditCustomCard = (id: string) => actions.openCard(id)

  // const handleSendCustomCard = () => {
  //   onClose()
  //   createCard({ card: cardId })
  // }

  if (!card) {
    throw new Error('Missing card')
  }

  // const cardImageUrl =
  //   card.__typename === 'SendableCard'
  //     ? card.frontImage.mediumThumb
  //     : card.frontPreviewUrl

  return (
    // <CardPreviewFlow
    //   onCardTypeSelect={(cardType: CardType) => {
    //     handleSetCardType(cardType)
    //     console.log(cardType, 'my new card type')
    //   }}
    //   onPaperTypeSelect={(paperType: CardPaperType) => {
    //     handleSetPaperType(paperType)
    //     console.log(paperType, 'papertype')
    //   }}
    //   cardImageUrl={cardImageUrl}
    //   initialStep="SelectSendType"
    //   onComplete={() => handleEditCard(card.id, cardType, paperType, false)}
    //   onChangeSendType={handleIsDigital}
    //   isDigitalSend={isDigital}
    //   onClose={onClose}
    // />

    // <div className={styles.cardPreview}>
    //   <div
    //     className={
    //       orientation === 'horizontal'
    //         ? `${styles.cardModal} ${styles.horizontalCard}`
    //         : orientation === 'portrait'
    //         ? `${styles.cardModal} ${styles.portraitCard}`
    //         : styles.cardModal
    //     }
    //   >
    //     <div
    //       className={
    //         isCustomizeOpen
    //           ? `${styles.previewContainer} ${styles.configurations}`
    //           : styles.previewContainer
    //       }
    //       id={'preview_container'}
    //     >
    //       {isMobile && <h4 style={{ textAlign: 'center' }}>Card Preview</h4>}
    //       <div className={styles.card}>
    //         {card.__typename === 'SendableCard' && (
    //           <CardFlip
    //             isFlipped={isCardFlipped}
    //             card={mergedSendableCard ?? card}
    //             isButtonEnabled={false}
    //             customCardSize={cardSizing}
    //             handleFavoriteCard={handleFavoriteCard}
    //           />
    //         )}
    //         {card.__typename === 'Card' && <CardPanelView card={card} />}
    //         <div
    //           style={
    //             {
    //               position: 'absolute',
    //               bottom: 10,
    //               left: '50%',
    //               transform: 'translateX(-50%)',
    //             } as React.CSSProperties
    //           }
    //         >
    //           {selectedVariation && variations && variations.length > 1 && (
    //             <VariationToggle
    //               style={{
    //                 bottom: 0,
    //                 boxShadow: '0px 2px 12px 0 #595959',
    //                 background: '#FFF',
    //               }}
    //               className={
    //                 orientation === 'horizontal'
    //                   ? `${styles.catalogVariation} ${styles.horizontalVariation}`
    //                   : `${styles.catalogVariation} ${styles.portraitVariation}`
    //               }
    //               variations={variations}
    //               selectedVariationId={selectedVariation.id}
    //               selectVariation={handleVariation}
    //             />
    //           )}
    //         </div>
    //       </div>
    //       <div className={styles.cardDetails}>
    //         <div className={styles.closePreview} onClick={() => onClose()}>
    //           <Icon icon={'CLOSE'} size={18} />
    //           <Text type="caption" color="primaryHeading">
    //             Close
    //           </Text>
    //         </div>
    //         <div className={styles.nonMobile}>
    //           {!isCardSelected && (
    //             <div className={styles.details}>
    //               <Text type="title">
    //                 {mergedSendableCard?.title ?? 'Custom Card'}
    //               </Text>
    //               {mergedSendableCard?.isPremium && (
    //                 <div className={styles.premiumTag}>
    //                   <Icon icon={'TAG'} size={18} color={'#F171CC'} />
    //                   <Div display="inline-block">
    //                     <Text
    //                       type="caption"
    //                       weight="bold"
    //                       color="primaryHeading"
    //                     >
    //                       Premium Card
    //                     </Text>
    //                   </Div>
    //                 </div>
    //               )}
    //               <Spacer space="x2" />
    //               <Text type="caption" color="primaryHeading">
    //                 {mergedSendableCard?.description ?? ''}
    //               </Text>
    //               {/* {!isFree && (
    //                 <FlexRow style={{ alignItems: 'flex-end' }}>
    //                   {getPrice}
    //                 </FlexRow>
    //               )} */}
    //               {/* {isFree && 'FREE'} */}
    //             </div>
    //           )}
    //           {type === 'Card' && (
    //             <>
    //               <div className={styles.actions}>
    //                 <Button
    //                   id={'edit_and_save_custom_card_btn'}
    //                   type="primary"
    //                   size="medium"
    //                   title="Edit and Save"
    //                   onClick={() => handleEditCustomCard(card.id)}
    //                   outlined={true}
    //                   fullWidth={true}
    //                 />
    //                 <Spacer orientation="horizontal" space="x2" />
    //                 <Button
    //                   id={'custom_card_to_editor_btn'}
    //                   type="primary"
    //                   size="medium"
    //                   title="Send Card"
    //                   onClick={handleSendCustomCard}
    //                   fullWidth={true}
    //                 />
    //               </div>
    //               {isSafari && <div style={{ height: '120px' }} />}
    //             </>
    //           )}
    //         </div>
    //         {mergedSendableCard && (
    //           <div
    //             className={styles.actions}
    //             style={
    //               isMobile
    //                 ? {
    //                     position: 'fixed',
    //                     bottom: '0px',
    //                     left: '0px',
    //                     right: '0px',
    //                   }
    //                 : { marginBottom: '25%' }
    //             }
    //           >
    //             {mergedSendableCard.insideRightImage && (
    //               <>
    //                 <Button
    //                   outlined={true}
    //                   title={isCardFlipped ? 'See Front' : 'See Inside'}
    //                   id={isCardFlipped ? 'see_front_btn' : 'see_inside_btn'}
    //                   onClick={handleCardFlip}
    //                 />
    //                 <Spacer space="x6" orientation="horizontal" />
    //               </>
    //             )}
    //             <FloatingDrawer
    //               isOpen={isCustomizeOpen}
    //               triggerTitle={'Customize'}
    //               triggerCloseTitle={'Cancel'}
    //               onClick={handleSelectCard}
    //               className={styles.action}
    //             >
    //               {isCardSelected && (
    //                 <div className={styles.options}>
    //                   <Div
    //                     inset={{ top: 'x4', horizontal: 'x2', bottom: 'x2' }}
    //                     backgroundColor="white"
    //                   >
    //                     <Text type="title" alignment="center">
    //                       {!['3666591', '3666592'].includes(
    //                         mergedSendableCard ? mergedSendableCard.id : '',
    //                       )
    //                         ? 'Customize Card Options'
    //                         : 'Enjoy the creative process!'}
    //                     </Text>
    //                   </Div>
    //                   <Spacer space="x2" />
    //                   <CardTypeOptions
    //                     options={filteredCardTypes}
    //                     optionTitle={'Pick a card type:'}
    //                     selectedOption={cardType}
    //                     selectOption={handleSetCardType}
    //                   />
    //                   {['FLATCARD', 'TWO_PANEL'].includes(cardType) ? (
    //                     <Flex style={{ marginLeft: '10px' }}>
    //                       {canShowBulkOrderOptions && (
    //                         <HStack
    //                           alignItems="center"
    //                           inset="x1"
    //                           justify="flex-start"
    //                           gap="x_5"
    //                         >
    //                           <Div
    //                             borderStyle="solid"
    //                             borderColor={{
    //                               swatch: 'success',
    //                               shade: 'base',
    //                             }}
    //                             borderRadius="circle"
    //                             style={{
    //                               lineHeight: 0,
    //                               borderWidth: '2px',
    //                               padding: '2px',
    //                             }}
    //                           >
    //                             <QdsIcon
    //                               primaryColor="success"
    //                               size="small"
    //                               name="check"
    //                             />
    //                           </Div>
    //                           <Text weight="bold" type="body">
    //                             Eligible for bulk send
    //                           </Text>
    //                         </HStack>
    //                       )}
    //                     </Flex>
    //                   ) : (
    //                     <Flex style={{ marginLeft: '10px' }}>
    //                       {canShowBulkOrderOptions && (
    //                         <HStack
    //                           alignItems="center"
    //                           inset="x1"
    //                           justify="flex-start"
    //                           gap="x_5"
    //                         >
    //                           <Div
    //                             borderStyle="solid"
    //                             borderColor={{
    //                               swatch: 'danger',
    //                               shade: 'base',
    //                             }}
    //                             borderRadius="circle"
    //                             style={{
    //                               lineHeight: 0,
    //                               borderWidth: '2px',
    //                               padding: '4px',
    //                             }}
    //                           >
    //                             <QdsIcon
    //                               primaryColor="danger"
    //                               size="xSmall"
    //                               name="close"
    //                             />
    //                           </Div>
    //                           <Text color="danger" weight="bold" type="body">
    //                             Not eligible for bulk send
    //                           </Text>
    //                         </HStack>
    //                       )}
    //                     </Flex>
    //                   )}
    //                   <Flex inset={{ bottom: '90px' }}>
    //                     <PaperTypeOptions
    //                       options={paperTypes}
    //                       optionTitle={'Choose Paper Type:'}
    //                       selectedOption={paperType}
    //                       selectOption={handleSetPaperType}
    //                     />
    //                   </Flex>
    //                   <Flex
    //                     justifyContent="center"
    //                     alignItems="center"
    //                     left="0"
    //                     position="fixed"
    //                     backgroundColor="background"
    //                     bottom={isMobile ? '80px' : '0'}
    //                     inset="x1"
    //                     width="100%"
    //                     zIndex={1001}
    //                   >
    //                     <Button
    //                       size="large"
    //                       fullWidth={true}
    //                       fontWeight="bold"
    //                       title="Next"
    //                       id={'card_preview_personalize_btn'}
    //                       onClick={() => {
    //                         if (canShowNewCardEditor) {
    //                           setShouldShowEditorChoiceModal(true)
    //                         } else {
    //                           const id = selectedVariation?.id ?? card?.id
    //                           if (id) {
    //                             handleEditCard(id, cardType, paperType, false)
    //                           }
    //                         }
    //                       }}
    //                     />
    //                   </Flex>
    //                 </div>
    //               )}
    //             </FloatingDrawer>
    //           </div>
    //         )}
    //       </div>
    //     </div>
    //   </div>
    //   <div className={styles.modalWindow} onClick={() => onClose()} />
    // </div>
    <></>
  )
}

export default suspenseBoundary({
  component: CardPreviewModal,
  unresolved: (
    <Transition
      message={'Loading your selection...'}
      messageStyle={{ color: 'black', fontWeight: 500 }}
    />
  ),
  failure: DefaultError,
})
