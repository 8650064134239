import React from 'react'
import { CardCategoryFragment } from 'src/legacy_graphql'
import { Flex, Image, Text } from '@sendoutcards/quantum-design-ui'
import { buildYourOwnCatImg64 } from './BuildYourOwnCatImg'

type AllCategoriesMobileProps = {
  categories: (
    | CardCategoryFragment
    | {
        __typename: string
        id: string
        description: string
        cards: never[]
        thumbnail: string
      }
  )[]
  handleBuildYourOwn: () => void
  handleCategory: (id: string) => void
  handleCustomCategory: () => void
}

export const AllCategoriesMobile: React.FC<AllCategoriesMobileProps> = ({
  categories,
  handleBuildYourOwn,
  handleCategory,
  handleCustomCategory,
}) => {
  return (
    <Flex flexDirection="row" flexWrap="wrap">
      <Flex width="100%" inset="x1" outset={{ bottom: 'x2' }}>
        <Text content="Cards" weight="bold" type="subtitle" />
      </Flex>
      <Flex width="100%" onClick={handleBuildYourOwn}>
        <Image
          borderRadius="none"
          isActive={true}
          image={{
            url: buildYourOwnCatImg64,
          }}
          width="100%"
          height="250px"
        />
      </Flex>
      {categories.map(category => {
        const handleOnClick =
          category.id === '3'
            ? handleCustomCategory
            : () => handleCategory(category.id)
        const resolvedCategoryThumbnailUrl = `${category.thumbnail}` ?? ''
        return (
          <Flex key={category.id} width="50%" onClick={handleOnClick}>
            <Image
              borderRadius="none"
              isActive={true}
              image={{
                url: resolvedCategoryThumbnailUrl,
              }}
              width="100%"
              height="250px"
            />
          </Flex>
        )
      })}
    </Flex>
  )
}
