import React from 'react'
// @src imports
import FontFace from 'src/editor/components/FontFace/FontFace'
import { Button, Transition } from 'src/chrome'
import { Footer } from 'src/styled'
import {
  CardType,
  LayoutCategory,
  LayoutDimensions,
  Owner,
} from 'src/graphql/generated/graphql'
import { getImages } from 'src/legacy_graphql'
import { GlobalEditor, GlobalEditorProvider } from '@sendoutcards/editor'
// relative imports

import EditorToolBar from '../../components/EditorToolBar/EditorToolBar'
import EditorTabs from '../EditorTabs/EditorTabs'
import EditorDrawer from '../EditorDrawer/EditorDrawer'
import ContextBar from '../../components/ContextBar/ContextBar'
import EditorFooterContents from '../../components/EditorFooterContents/EditorFooterContents'
import styles from './styles'
import { Props, Steps } from '../../types'
import apiFactory from '../../api'
import {
  useAccount,
  useActions,
  useCallback,
  useDeviceInfo,
  useEffect,
  useMutations,
  useQueries,
  useSelector,
  useState,
} from 'src/hooks'
import range from 'lodash/range'
import {
  editorFullBleed,
  newEditorCard,
  newEditorColor,
  newEditorLayout,
  stickerFragmentToImageType,
  userImageFragmentToImageType,
} from '../../utils'
import {
  Button as QDButton,
  ConfirmDialog,
  Dialog,
  Div,
  Flex,
  HStack,
  Icon,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { getCardDimensions, isNotNullOrUndefined } from 'src/helpers'
import { selectedVariationId as getSelectedVariationId } from '../../../redux/selectors/editor'
import removeUsersnap from 'src/helpers/removeUsersnap'
import { Card } from '@sendoutcards/editor/dist/types/card'
import { MobileContextBar } from './components/MobileContextBar'
import { ToasterNotification } from '../../components/MobileEditorToolbar/components/ToasterNotification'
import { AnimatePresence } from 'framer-motion'
import { Portal } from 'src/portal/portal'
import { countLineItems } from 'src/orders/helpers'
import {
  MiniCart,
  MiniCartProps,
} from 'src/orders/components/MiniCart/MiniCart'
import {
  useDeletePanelLayout,
  useInfiniteLayouts,
  useLayouts,
  useStickerCategories,
  useStickers,
  useUpdateAccount,
} from 'src/react_query'
import { factoryFBForNewCardEditor } from 'src/editor/EditorCard'
import useScreenOrientation from 'src/hooks/useScreenOrientation'
import { SendButton } from 'src/editor/components/SendButton/SendButton'
import { SaveActionZone } from '@sendoutcards/editor/dist/containers/GlobalEditor/GlobalEditor'

const hasSpanView = (cardType: CardType): boolean => {
  switch (cardType) {
    case CardType.TwoPanel:
    case CardType.ThreePanel:
    case CardType.TwoPanelBig:
      return true
    default:
      return false
  }
}

type leaveOptions = 'catalog' | 'cardHistory' | 'myAccount'
type Status = 'none' | 'loading' | 'error' | 'success'

const Editor: React.FC<Props> = props => {
  const api = apiFactory(props)

  const {
    context,
    saveButtonLabel,
    saveButtonBackground,
    saveButtonColor,
    onBackToCatalog,
    orderApi,
    handleAddToOrder,
    onReload,
  } = props
  const {
    step,
    isMobile,
    resolveLocation,
    card,
    fullBleeds,
    setFullBleeds,
    fonts,
    isSaving,
    setIsSaving,
    panelView,
    setPanelView,
    createOrUpdateBackPanel,
    handleSave,
    handleSaveAndSend,
    selectedBackLayoutId,
    setSelectedBackLayoutId,
    toasterNotification,
    setToasterNotification,
    canShowNewCardEditor,
    errorState,
    setErrorState,
  } = api

  const hasContextBar = !!context?.lines

  // TODO: React Query Refactor, not sure if this is what we want to do
  // but RQ returns potentially undefined results
  if (!card) throw Error('No card provided!')

  const [shouldShowNewCardEditor] = useState(card.isNewEditorCard)
  const [showConfirmVisible, setShowConfirmVisible] = useState(false)
  const [selectedVariationID] = useState(
    getSelectedVariationId(card, fullBleeds),
  )
  const [selectedStickerCategory, setSelectedStickerCategory] = useState<
    string | undefined
  >()
  const allFonts = fonts.all ?? []

  // NOTE: This is a temporary tool for local test of the new card editor
  // Switch this to true when using a local build of the current soc-editor
  const shouldUseNewWIPCardEditor = true

  const [newCard, setNewCard] = useState<Card>(
    newEditorCard(
      card,
      shouldUseNewWIPCardEditor ? factoryFBForNewCardEditor(card) : fullBleeds,
      allFonts,
      selectedVariationID ?? null,
    ),
  )

  const [shouldShowConfirmLeave, setShouldShowConfirmLeave] = useState(false)
  const [savingTransitionMessage, setSavingTransitionMessage] = useState(
    'Saving your card...',
  )

  const [leaveTo, setLeaveTo] = useState<leaveOptions>('catalog')
  const showNewCardEditor = shouldShowNewCardEditor && canShowNewCardEditor
  const mutations = useMutations()
  const actions = useActions()
  const updateAccountMutation = useUpdateAccount()
  const deletePanelLayoutMutation = useDeletePanelLayout()
  const account = useAccount()
  const { orders } = useSelector(state => state)
  const deviceInfo = useDeviceInfo()
  const { orientation } = useScreenOrientation()
  const [shouldShowLandscapeWarning, setShouldShowLandscapeWarning] = useState(
    false,
  )
  const [mainCss, setMainCss] = useState(styles.main)

  const [images] = useQueries(showNewCardEditor ? getImages() : undefined)

  const layoutsQuery = useLayouts(
    {
      category: LayoutCategory.BasicLayouts,
      offset: 0,
      limit: 100,
      dimensions: getCardDimensions(card),
    },
    { enabled: showNewCardEditor },
  )
  const layouts = layoutsQuery.data

  const systemBackPanelsQuery = useInfiniteLayouts({
    category: LayoutCategory.BackPanels,
    owner: Owner.System,
  })
  const systemBackPanels = systemBackPanelsQuery.data

  const backPanelsQuery = useInfiniteLayouts({
    category: LayoutCategory.BackPanels,
    owner: Owner.User,
    dimensions: LayoutDimensions.Vertical,
  })
  const backPanels = backPanelsQuery.data

  const stickerCategoriesQuery = useStickerCategories()

  const {
    data: stickers,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useStickers({
    categories: selectedStickerCategory
      ? [{ name: selectedStickerCategory }]
      : undefined,
  })

  const miniCartProps: MiniCartProps = {
    orderId: orders.order ? orders.order.id : '',
    editorCard: api.card,
    containerId: 'editor_context_bar',
    button: {
      count: orders.order && countLineItems(orders.order.lines),
      shouldBypassDrawer: false,
    },
    checkoutAction: {
      onCheckout: () =>
        handleSaveAndSend().catch(error => console.error(error)),
      title: 'Proceed to Cart',
    },
    previewZIndex: 1002,
    orderPreviewActionOverride: {
      onAddGift: {
        title: 'How would you like to proceed?',
        description:
          'Would you like to add this card to your order and also add a gift or discard this card and proceed to the gift store?',
        accept: {
          title: 'Add card to order and proceed to add a gift',
          shouldUseDefaultAction: true,
          onClick: async () => {
            setIsSaving(true)
            try {
              await handleSave()
              setIsSaving(true)
              setSavingTransitionMessage('Saving your card...')
              await handleAddToOrder?.(card.id)
              setIsSaving(true)
              setSavingTransitionMessage('Adding your card to your order...')
              await onReload?.()
            } catch (error) {
              console.error(error)
            } finally {
              setIsSaving(false)
            }
          },
        },
        decline: {
          title: 'Discard current card and continue to gift store',
          shouldUseDefaultAction: true,
        },
      },
    },
    orderApi: orderApi,
  }

  const [isReadyToSend, setIsReadyToSend] = useState(false)
  const [
    contextZoneOptionsIsLoadingMap,
    setContextZoneOptionsIsLoadingMap,
  ] = useState<{ [id: string]: Status }>({})
  const saveChangesTitle = 'Save Changes'
  const saveExistingBackPanelTitle = 'Save Back Panel'
  const saveNewBackPanelTitle = 'Save as New Back Panel'
  const saveAndCloseCardEditorTitle = 'Save & Close Card Editor'

  useEffect(() => {
    if (!showNewCardEditor) {
      removeUsersnap()
    }
  }, [showNewCardEditor])

  useEffect(() => {
    setNewCard(
      newEditorCard(
        card,
        shouldUseNewWIPCardEditor
          ? factoryFBForNewCardEditor(card)
          : fullBleeds,
        allFonts,
        selectedVariationID ?? null,
      ),
    )
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (
      deviceInfo.device === 'AndroidPhone' ||
      deviceInfo.device === 'iPhone'
    ) {
      if (/landscape/i.test(orientation)) {
        setShouldShowLandscapeWarning(true)
      } else {
        setShouldShowLandscapeWarning(false)
      }
    }
  }, [orientation, deviceInfo.device])

  // Add/Removed toast notification clear
  useEffect(() => {
    if (toasterNotification) {
      setTimeout(() => {
        setToasterNotification(null)
      }, 2800)
    }
  }, [toasterNotification, setToasterNotification])

  const updateIosHeight = useCallback(() => {
    setMainCss({
      ...(styles.main as Record<string, unknown>),
      height: `${window.innerHeight}px`,
    })
  }, [])

  useEffect(() => {
    if (deviceInfo.os === 'iOS' && !CSS.supports('height', '100dvh')) {
      updateIosHeight()
      window.addEventListener('resize', updateIosHeight)
      return () => {
        window.removeEventListener('resize', updateIosHeight)
      }
    } else if (
      (deviceInfo.os === 'MacOS' && deviceInfo.browser === 'Safari') ||
      !CSS.supports('height', '100dvh')
    ) {
      setMainCss({
        ...(styles.main as Record<string, unknown>),
        height: '100vh',
      })
    }
    return
  }, [deviceInfo.os, deviceInfo.browser, updateIosHeight])

  const landscapeWarning = shouldShowLandscapeWarning && (
    <Portal attachToContainerId="app">
      <Dialog
        isOpen={shouldShowLandscapeWarning}
        zIndex={10001}
        insetOverride="x4"
      >
        <Flex justifyContent="center">
          <Icon name="imageSwap" size={50} primaryColor="primaryBodyText" />
        </Flex>
        <Spacer space="x2" />
        <Text
          type="subtitle"
          weight="bold"
          color="primaryHeading"
          alignment="center"
        >
          Landscape mode not supported
        </Text>
        <Spacer space="x2" />
        <Text type="body" alignment="center">
          We currently do not support landscape mode for mobile devices. For a
          better experience please switch to portrait mode.
        </Text>
        <Spacer space="x2" />
        <QDButton
          type="primary"
          onClick={() => setShouldShowLandscapeWarning(false)}
          fullWidth={true}
        >
          Ok
        </QDButton>
      </Dialog>
    </Portal>
  )

  const errorDialog = errorState && (
    <Dialog isOpen={true} onClose={() => setErrorState(undefined)}>
      <Flex flexDirection="column" alignItems="center" rowGap="x2">
        <Text type="subtitle" alignment="center">
          {errorState.title}
        </Text>
        <Text type="body" alignment="center">
          For further assistance, contact the Customer Success team at (801)
          463-3800 to resolve this issue.
        </Text>
        <Text type="body">Report error code: {`${errorState.error}`}</Text>
        <Button title="Ok" onClick={() => setErrorState(undefined)} />
      </Flex>
    </Dialog>
  )

  const [shouldShowSaveAndClose, setShouldShowSaveAndClose] = useState(false)

  if (showNewCardEditor) {
    if (
      (!layouts && layoutsQuery.isLoading) ||
      (!backPanels && backPanelsQuery.isLoading) ||
      (!systemBackPanels && systemBackPanelsQuery.isLoading)
    ) {
      return <Transition message="Loading editor" />
    }
    const mutableContextZoneOptions: SaveActionZone[] = [
      {
        onClick: async () => {
          setContextZoneOptionsIsLoadingMap(prevState => ({
            ...prevState,
            [saveChangesTitle]: 'loading',
          }))
          try {
            await handleSave()
            setContextZoneOptionsIsLoadingMap(prevState => ({
              ...prevState,
              [saveChangesTitle]: 'success',
            }))
            setToasterNotification({
              iconName: 'check',
              content: 'Saved changes',
              type: 'success',
            })
          } catch (error) {
            console.error(error)
            setToasterNotification({
              iconName: 'close',
              content: 'Failed to save changes',
              type: 'danger',
            })
            setContextZoneOptionsIsLoadingMap(prevState => ({
              ...prevState,
              [saveChangesTitle]: 'error',
            }))
          } finally {
            window.setTimeout(() => {
              setContextZoneOptionsIsLoadingMap(prevState => ({
                ...prevState,
                [saveChangesTitle]: 'none',
              }))
            }, 2000)
          }
        },
        title: saveChangesTitle,
        status: contextZoneOptionsIsLoadingMap[saveChangesTitle] ?? 'none',
        isActive:
          contextZoneOptionsIsLoadingMap[saveChangesTitle] &&
          contextZoneOptionsIsLoadingMap[saveChangesTitle] !== 'none',
      },
      {
        onClick: () => setShouldShowSaveAndClose(true),
        title: saveAndCloseCardEditorTitle,
        status:
          contextZoneOptionsIsLoadingMap[saveAndCloseCardEditorTitle] ?? 'none',
      },
      ...(selectedBackLayoutId
        ? [
            {
              title: saveExistingBackPanelTitle,
              onClick: async () => {
                setContextZoneOptionsIsLoadingMap(prevState => ({
                  ...prevState,
                  [saveExistingBackPanelTitle]: 'loading',
                }))

                try {
                  await createOrUpdateBackPanel()
                } catch (error) {
                  console.error(error)
                  setToasterNotification({
                    iconName: 'close',
                    content: 'Failed to save changes',
                    type: 'danger',
                  })
                  setContextZoneOptionsIsLoadingMap(prevState => ({
                    ...prevState,
                    [saveExistingBackPanelTitle]: 'error',
                  }))
                } finally {
                  window.setTimeout(() => {
                    setContextZoneOptionsIsLoadingMap(prevState => ({
                      ...prevState,
                      [saveExistingBackPanelTitle]: 'none',
                    }))
                  }, 2000)
                }
              },
              status:
                contextZoneOptionsIsLoadingMap[saveExistingBackPanelTitle] ??
                'none',
              isActive:
                contextZoneOptionsIsLoadingMap[saveExistingBackPanelTitle] &&
                contextZoneOptionsIsLoadingMap[saveExistingBackPanelTitle] !==
                  'none',
              isBackPanelOnly: true as const,
            },
            {
              title: saveNewBackPanelTitle,
              onClick: async () => {
                setContextZoneOptionsIsLoadingMap(prevState => ({
                  ...prevState,
                  [saveNewBackPanelTitle]: 'loading',
                }))

                try {
                  await createOrUpdateBackPanel()
                } catch (error) {
                  console.error(error)
                  setToasterNotification({
                    iconName: 'close',
                    content: 'Failed to save changes',
                    type: 'danger',
                  })
                  setContextZoneOptionsIsLoadingMap(prevState => ({
                    ...prevState,
                    [saveNewBackPanelTitle]: 'error',
                  }))
                } finally {
                  window.setTimeout(() => {
                    setContextZoneOptionsIsLoadingMap(prevState => ({
                      ...prevState,
                      [saveNewBackPanelTitle]: 'none',
                    }))
                  }, 2000)
                }
              },
              status:
                contextZoneOptionsIsLoadingMap[saveNewBackPanelTitle] ?? 'none',
              isActive:
                contextZoneOptionsIsLoadingMap[saveNewBackPanelTitle] &&
                contextZoneOptionsIsLoadingMap[saveNewBackPanelTitle] !==
                  'none',
              isBackPanelOnly: true as const,
            },
          ]
        : []),
    ]
    // tslint:disable-next-line
    const fullName = `${account.firstName} ${account.lastName}`

    const setBackPanelDefaultLayout = async (id: string) => {
      const result = await updateAccountMutation.mutateAsync({
        account: { settings: { defaultBackPanelId: id } },
      })
      actions.updatedAccount(result.updateAccount.account)
    }

    const confirmedLeave = () => {
      setShouldShowConfirmLeave(false)
      api.onClose()
      switch (leaveTo) {
        case 'cardHistory':
          actions.openCardHistory()
          return
        case 'myAccount':
          actions.openAccount()
          return
        case 'catalog':
          actions.openCatalog()
          return
        default:
          return
      }
    }

    const handleSelectStickerCategory = (stickerCategoryName: string) => {
      if (selectedStickerCategory !== stickerCategoryName) {
        setSelectedStickerCategory(stickerCategoryName)
      } else {
        setSelectedStickerCategory(undefined)
      }
    }

    const getSaveAndCloseTitle = () => {
      const saveStatus =
        contextZoneOptionsIsLoadingMap[saveAndCloseCardEditorTitle] ?? 'none'
      switch (saveStatus) {
        case 'loading':
          return 'Saving...'
        case 'success':
          return 'Saved!'
        case 'error':
          return 'Failed to save'
        default:
          return 'Continue and navigate away'
      }
    }

    return (
      <GlobalEditorProvider>
        <style>{'html { overscroll-behavior: none }'}</style>
        {landscapeWarning}
        {errorDialog}
        {shouldShowConfirmLeave && (
          <ConfirmDialog
            title="You have made unsaved changes to this card"
            description="This card will be available in your Account under 'Drafted Cards'. Any unsaved changes will be lost."
            primaryAction="decline"
            isOpen={shouldShowConfirmLeave}
            accept={{
              title: 'Continue without saving',
              onClick: confirmedLeave,
            }}
            decline={{
              title: 'Return to card editor',
              onClick: () => {
                setShouldShowConfirmLeave(false)
              },
            }}
            onClose={() => {
              setShouldShowConfirmLeave(false)
            }}
          />
        )}
        {shouldShowSaveAndClose && (
          <ConfirmDialog
            title="Are you sure you want to leave?"
            description="This card will be available in your Account under 'Drafted Cards'."
            primaryAction="accept"
            isOpen={shouldShowSaveAndClose}
            accept={{
              title: getSaveAndCloseTitle(),
              onClick: async () => {
                setContextZoneOptionsIsLoadingMap(prevState => ({
                  ...prevState,
                  [saveAndCloseCardEditorTitle]: 'loading',
                }))
                try {
                  await handleSave()
                  setContextZoneOptionsIsLoadingMap(prevState => ({
                    ...prevState,
                    [saveAndCloseCardEditorTitle]: 'success',
                  }))
                  setTimeout(() => {
                    setShouldShowSaveAndClose(false)
                    api.onClose()
                  }, 1500)
                } catch (error) {
                  console.error(error)
                  setContextZoneOptionsIsLoadingMap(prevState => ({
                    ...prevState,
                    [saveAndCloseCardEditorTitle]: 'error',
                  }))
                } finally {
                  setTimeout(() => {
                    setContextZoneOptionsIsLoadingMap(prevState => ({
                      ...prevState,
                      [saveAndCloseCardEditorTitle]: 'none',
                    }))
                  }, 2000)
                }
              },
            }}
            decline={{
              title: 'Cancel and stay on this page',
              onClick: () => {
                setShouldShowSaveAndClose(false)
              },
            }}
            onClose={() => {
              setShouldShowSaveAndClose(false)
            }}
          />
        )}
        <GlobalEditor
          colorPalettes={{
            palette: api.designColors?.palette?.map(color =>
              newEditorColor(color),
            ),
            isLoading: api.designColors.isLoading,
          }}
          defaultBackPanel={[
            account.settings?.defaultBackPanel?.id ?? '',
            setBackPanelDefaultLayout,
          ]}
          userInfo={{
            id: account.id,
            name: fullName,
            email: account.email,
          }}
          card={newCard}
          fonts={fonts.fonts}
          signatures={fonts.signatures}
          placeholders={[
            'First Name',
            'Last Name',
            'Spouse Name',
            'First Name & Spouse Name',
          ]}
          layouts={
            layouts?.map(layout => newEditorLayout(allFonts)(layout)) ?? []
          }
          defaultBackPanels={{
            isLoadingMore: systemBackPanelsQuery.isFetchingNextPage,
            hasMore: !!systemBackPanelsQuery.hasNextPage,
            loadMore: systemBackPanelsQuery.fetchNextPage,
            results:
              systemBackPanels?.pages
                .flatMap(p => p.layouts)
                .map(layout => newEditorLayout(allFonts)(layout)) ?? [],
          }}
          customBackPanels={{
            isLoadingMore: backPanelsQuery.isFetchingNextPage,
            hasMore: !!backPanelsQuery.hasNextPage,
            loadMore: backPanelsQuery.fetchNextPage,
            results:
              backPanels?.pages
                .flatMap(p => p.layouts)
                .map(layout => newEditorLayout(allFonts)(layout)) ?? [],
          }}
          images={{
            ...images,
            hasMore: images?.hasMore ?? false,
            loadMore: images?.loadMore ?? (() => {}),
            isLoadingMore: images?.isLoadingMore ?? false,
            results:
              images?.results.map(result =>
                userImageFragmentToImageType(result),
              ) ?? [],
          }}
          stickers={{
            hasMore: hasNextPage ?? false,
            isLoadingMore: isFetchingNextPage,
            loadMore: fetchNextPage,
            results:
              stickers?.pages
                .flatMap(page => page.results)
                .map(result => stickerFragmentToImageType(result)) ?? [],
          }}
          onDeleteBackPanel={id =>
            deletePanelLayoutMutation.mutateAsync({ id })
          }
          isLoading={isSaving}
          onSelectBackLayout={setSelectedBackLayoutId}
          onUploadImages={files =>
            range(files.length)
              .map(index => files.item(index))
              .filter(isNotNullOrUndefined)
              .forEach(image_file =>
                mutations.uploadImage({
                  image_file,
                  tags: ['prompt-editor'],
                }),
              )
          }
          onDeleteImage={id => mutations.deleteImage({ id })}
          onSave={({ fullBleeds }) => {
            if (allFonts.length === 0) return
            setFullBleeds(fullBleeds.map(editorFullBleed(allFonts)))
          }}
          contextZones={{
            save: {
              isLoading: isSaving,
              loadingMessage: 'Saving Changes',
              options: mutableContextZoneOptions,
            },
            clientNavigation: [
              {
                section: {
                  title: 'Menu',
                  description: '',
                  links: [
                    {
                      title: 'Back To Catalog',
                      icon: 'catalog',
                      onClick: () => {
                        setShouldShowConfirmLeave(true)
                        setLeaveTo('catalog')
                      },
                      key: 'catalog',
                    },
                    {
                      title: 'Card History',
                      icon: 'envelope',
                      onClick: () => {
                        setShouldShowConfirmLeave(true)
                        setLeaveTo('cardHistory')
                      },
                      key: 'history',
                    },
                    {
                      title: 'My Account',
                      icon: 'user',
                      onClick: () => {
                        setShouldShowConfirmLeave(true)
                        setLeaveTo('myAccount')
                      },
                      key: 'account',
                    },
                  ],
                },
              },
            ],
            primary: (
              <SendButton
                onClick={async () => {
                  setIsReadyToSend(true)
                  try {
                    await handleSaveAndSend()
                  } catch (e) {
                    console.error(e)
                  } finally {
                    setIsReadyToSend(false)
                  }
                }}
                title={isMobile ? undefined : 'Send'}
                isLoading={isReadyToSend}
              />
            ),
            externalActions: (
              <HStack justify="flex-end" gap="x2_5" outset={{ left: 'x2' }}>
                <MiniCart {...miniCartProps} height="31px" />
                {context?.footerLabel(card)}
              </HStack>
            ),
          }}
          textScaleRatio={1.25}
          isUsingOldFlattener={false}
          stickerCategories={{
            results:
              stickerCategoriesQuery.data?.pages
                .flatMap(page => page.results)
                .map(stickerCategory => stickerCategory.name) ?? [],
            onSelect: handleSelectStickerCategory,
          }}
        />
        <Div
          borderRadius={{ left: { xSmall: 'large' }, xLarge: 'circle' }}
        ></Div>
      </GlobalEditorProvider>
    )
  }

  return (
    <div
      className="order-editor"
      css={() => styles.container(step.type !== Steps.Idle)}
    >
      {landscapeWarning}
      {errorDialog}
      <FontFace fonts={api.fonts} />
      {isSaving && <Transition message={savingTransitionMessage} />}
      <div css={styles.sidebar}>
        <EditorToolBar
          api={api}
          setShowConfirmVisible={setShowConfirmVisible}
          showConfirmVisible={showConfirmVisible}
          onBackToCatalog={onBackToCatalog}
        />
      </div>
      <div css={styles.drawerContainer}>
        {step.type !== Steps.Idle && <EditorDrawer api={api} />}
      </div>
      <div id={'main'} css={mainCss}>
        {!isMobile && context?.headerText && (
          <ContextBar
            lines={context?.lines}
            title={context?.headerText}
            subtitle={context?.headerSubText}
            activeCardId={card.id}
          />
        )}
        <>
          <div css={styles.headerContainer} id="promo-modal-container">
            {isMobile && (
              <MobileContextBar
                miniCartProps={miniCartProps}
                onExit={() => setShowConfirmVisible(true)}
                cardType={card.type}
                panelLocation={resolveLocation(card.type)}
                isFullBleed={panelView === 'fullbleed'}
                onChangePanelView={() =>
                  setPanelView(panelView === 'panel' ? 'fullbleed' : 'panel')
                }
              />
            )}
            {!isMobile && hasSpanView(card.type) && (
              <Button
                title={panelView === 'fullbleed' ? 'Panel View' : 'Span View'}
                onClick={() =>
                  setPanelView(
                    panelView === 'fullbleed' ? 'panel' : 'fullbleed',
                  )
                }
                style={{
                  position: 'absolute',
                  right: 15,
                  zIndex: 102,
                  ...(hasContextBar || context?.headerText ? { top: 90 } : {}),
                }}
              />
            )}
          </div>
          <EditorTabs
            saveButtonLabel={saveButtonLabel}
            api={api}
            swipeableViewWidth={
              isMobile
                ? '100%'
                : step.type !== Steps.Idle
                ? 'calc(100vw - 380px)'
                : 'calc(100vw - 65px)'
            }
          />
        </>
        {!isMobile && card && (
          <Footer css={styles.footer}>
            <EditorFooterContents
              label={context?.footerLabel(card)}
              saveButtonLabel={saveButtonLabel}
              saveButtonBackground={saveButtonBackground}
              saveButtonColor={saveButtonColor}
              onSave={() => {
                handleSaveAndSend().catch(error => console.error(error))
              }}
              miniCartProps={miniCartProps}
            />
          </Footer>
        )}
      </div>

      <AnimatePresence>
        {isMobile && toasterNotification && (
          <ToasterNotification
            backgroundColor={{
              swatch: toasterNotification.type,
              shade: '_500',
            }}
            icon={{
              size: 'xSmall',
              name: toasterNotification.iconName,
              primaryColor: 'inverseHeadingText',
              iconContainerColor: { swatch: 'success', shade: '_400' },
            }}
            label={{
              color: 'inverseHeading',
              type: 'footnote',
              content: toasterNotification.content,
            }}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default Editor
